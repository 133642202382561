import Redactor from '../vendor/redactor-4-1-0/redactor.usm.min.js';
import '../vendor/redactor-4-1-0/plugins/alignment/alignment.js';
import '../vendor/redactor-4-1-0/plugins/blockcode/blockcode.js';
import '../vendor/redactor-4-1-0/plugins/counter/counter.js';
import '../vendor/redactor-4-1-0/plugins/imageresize/imageresize.js';
import '../vendor/redactor-4-1-0/plugins/imageposition/imageposition.js';
import '../vendor/redactor-4-1-0/plugins/mention/mention.js';
import '../vendor/redactor-4-1-0/plugins/icons/icons.js';
import '../vendor/redactor-4-1-0/plugins/emoji/emoji.js';
import '../vendor/redactor-4-1-0/plugins/textdirection/textdirection.js';
import '../vendor/redactor-4-1-0/redactor.css';
import '../vendor/redactor-4-1-0/custom.css';

document.addEventListener('turbolinks:load', () => {
  initializeRedactor();
});

function initializeRedactor() {
  Redactor('.js-redactor', {
    control: true,
    context: true,
    reorder: true,
    nostyle: true,
    theme: 'light',
    layout: {
      grid: 'grid'
    },
    layouts: {
      set: true,
      'one-column': {
        title: 'One column',
        grid: 'grid',
        pattern: '-'
      },
      'two-columns': {
        title: 'Two columns',
        grid: 'grid grid-cols-2 gap-4',
        pattern: '-|-'
      },
      'three-columns': {
        title: 'Three columns',
        grid: 'grid grid-cols-3 gap-4',
        pattern: '-|-|-'
      },
      'four-columns': {
        title: 'Four columns',
        grid: 'grid grid-cols-4 gap-4',
        pattern: '-|-|-|-'
      }
    },
    // toolbar: false,
    plugins: [
      'alignment',
      'blockcode',
      'counter',
      'imageresize',
      'imageposition',
      'mention',
      'icons',
      'emoji',
      'textdirection'
    ],
    image: {
      upload: '/editor_images'
    },
    mention: {
      url: '/mentions'
    },
    // use Tailwind text alignment class
    // https://tailwindcss.com/docs/text-align
    alignment: {
      align: {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
        justify: 'text-justify'
      }
    },
    addbar: {
      add: {
        'divider': {
            title: 'Divider',
            position: { after: 'text' },
            icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" /></svg>',
            template: '<p class="text-center">* * *</p>'
        }
      }
    },
    classes: {
      tags: {
        p: 'mt-3',
        a: 'text-brand-blue',
        ul: 'list-disc',
        ol: 'list-decimal'
      }
    },
    subscribe: {
      'editor.before.paste': function(event) {
        var file = event.params.e.clipboardData.files[0];
        if (file) {
          // Override the default paste event to upload pasted image
          this.image.paste(file)
          event.stop()
        } else {
          // get pasted html
          var html = event.get('html');

          // Replace div tags with p tags so the pasted content has better code format
          html = html.replace(/<(\/*)div([^>]*)>/g, '<$1p$2>');

          // return html back
          event.set('html', html);
        }
      }
    }
  });
}
