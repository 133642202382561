Redactor.add('plugin', 'imageposition', {
    translations: {
        en: {
            "imageposition": {
                "image-position": "Image position",
                "left": "Left",
                "center": "Center",
                "right": "Right",
            }
        }
    },
    defaults: {
        context: false,
        icon: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m15 13c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-13c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1zm-5-12c.5522847 0 1 .44771525 1 1v8c0 .5522847-.4477153 1-1 1h-8c-.55228475 0-1-.4477153-1-1v-8c0-.55228475.44771525-1 1-1zm5 8c.5522847 0 1 .44771525 1 1 0 .5522847-.4477153 1-1 1h-2c-.5522847 0-1-.4477153-1-1 0-.55228475.4477153-1 1-1zm-10-1-1 1h2zm4-5h-6v5l2-2 3 3h1zm6 2c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1zm-8-1c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1zm8-3c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1z"/></svg>',
        items: ['left', 'center', 'right'],
        icons: {
            left: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m10 3c.5522847 0 1 .44771525 1 1v8c0 .5522847-.4477153 1-1 1h-8c-.55228475 0-1-.4477153-1-1v-8c0-.55228475.44771525-1 1-1zm5 8c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1zm-10-1-1 1h2zm4-5h-6v5l2-2 3 3h1zm6 2c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1zm-8-1c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1zm8-3c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1z"/></svg>',
            center: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m12 3c.5522847 0 1 .44771525 1 1v8c0 .5522847-.4477153 1-1 1h-8c-.55228475 0-1-.4477153-1-1v-8c0-.55228475.44771525-1 1-1zm3 8c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1-1-.4477153-1-1 .4477153-1 1-1zm-14 0c.55228475 0 1 .4477153 1 1s-.44771525 1-1 1-1-.4477153-1-1 .44771525-1 1-1zm6-1-1 1h2zm4-5h-6v5l2-2 3 3h1zm4 2c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1-1-.44771525-1-1 .4477153-1 1-1zm-14 0c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1zm8-1c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1zm6-3c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1-1-.44771525-1-1 .4477153-1 1-1zm-14 0c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1z"/></svg>',
            right: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m14 3c.5522847 0 1 .44771525 1 1v8c0 .5522847-.4477153 1-1 1h-8c-.55228475 0-1-.4477153-1-1v-8c0-.55228475.44771525-1 1-1zm-11 8c.55228475 0 1 .4477153 1 1s-.44771525 1-1 1h-2c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1zm6-1-1 1h2zm4-5h-6v5l2-2 3 3h1zm-10 2c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1h-2c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1zm8-1c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1-1-.44771525-1-1 .4477153-1 1-1zm-8-3c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1h-2c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1z"/></svg>'
        }
    },
    start: function() {
        this.app.toolbar.add('imageposition', {
            title: '## imageposition.image-position ##',
            command: 'imageposition.popup',
            icon: this.opts.get('imageposition.icon'),
            position: {
                after: 'image'
            },
            blocks: {
                types: ['image']
            }
        });
    },
    popup: function(e, button) {
        let buttons = this.opts.get('imageposition.items'),
            currentAlign = this.get(),
            finalButtons = {};

        for (let i = 0; i < buttons.length; i++) {
            let key = buttons[i];
            let item = {
                command: 'imageposition.set',
                title: this.lang.get('imageposition.' + key),
                active: (currentAlign === key),
                icon: this.opts.get('imageposition.icons.' + key),
                params: {
                    name: key
                }
            };

            finalButtons[key] = item;
        }

        let dropdown = this.app.create('dropdown', 'imageposition', { items: finalButtons });
        this.app.dropdown.open(e, button, dropdown);
    },
    get: function() {
        let instance = this.app.block.get();
        if (!instance) {
            return;
        }
        let className = instance.$image.nodes[0].className;
        return this._getCurrentAlign(className);
    },
    set: function(params) {
        this.app.dropdown.close();
        this.app.editor.restore();

        var instance = this.app.block.get();
        instance.$image.nodes[0].className = this._getClassName(params.name)
    },
    _getClassName(key) {
        if (key == 'center') {
            return 'mx-auto';
        } else if (key == 'right') {
            return 'ml-auto';
        } else {
            return 'mr-right';
        }
    },
    _getCurrentAlign(className) {
        if (className == 'mx-auto') {
            return 'center';
        } else if (className == 'ml-auto') {
            return 'right';
        } else {
            return 'left';
        }
    }
});
